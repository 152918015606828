import { createStore } from '~/store/createStore';
export const useGoOffStore = createStore((set, get) => {
    return {
        go: undefined,
        off: undefined,
        virtualSymbol: '',
        virtualEntryDate: '',
        virtualQty: 0,
    };
});
