import { sortBy } from 'lodash';
import { useSortVirtualHoldingDataStore, useSortSignalrDataStore, } from '~/modules/symbolQuote/simple/useSortStore';
import { useSnapshot } from 'valtio';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
/** 持有中商品排序 */
export const useVirtualHoldingSort = (data) => {
    const sortSnap = useSnapshot(useSortVirtualHoldingDataStore);
    const sortKey = sortSnap.sortKey;
    const orderKey = sortSnap.orderKey;
    const qty = data?.map(datum => datum.qty);
    const profitData = qty?.includes(1)
        ? sortBy(data, datum => 1 - datum.averagePrice / datum.marketPrice)
        : sortBy(data, datum => 1 - datum.marketPrice / datum.averagePrice);
    const orderData = orderKey === 'desc'
        ? sortBy(data, datum => datum?.[sortKey]).reverse()
        : sortBy(data, datum => datum?.[sortKey]);
    const orderProfitData = orderKey === 'desc' ? profitData.reverse() : profitData;
    const resultData = sortKey === 'profitPercentage' ? orderProfitData : orderData;
    return resultData;
};
/** 一般商品排序 ex:自選股 */
export const useSignalrQuoteSort = (data) => {
    const sortSnap = useSnapshot(useSortSignalrDataStore);
    const sortKey = sortSnap.sortKey;
    const orderKey = sortSnap.orderKey;
    const value = useSignalrStoreValueOHLC(state => state.useGroupOHLC(data ?? []));
    const valueData = () => {
        if (sortKey === 'change')
            return sortBy(value, datum => (datum?.close ?? 0) - (datum?.prevRef ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === 'changePrecent')
            return sortBy(value, datum => ((datum?.close ?? 0) / (datum?.prevRef ?? 0)) * 0.01).map(datum => datum?.symbol);
        else if (sortKey === 'none')
            return value.map(datum => datum?.symbol);
        else if (sortKey === 'amount')
            return sortBy(value, datum => (datum?.close ?? 0) * (datum?.volume ?? 0)).map(datum => datum?.symbol);
        else if (sortKey === sortKey)
            return sortBy(value, datum => datum?.[sortKey]).map(datum => datum?.symbol);
    };
    const resultData = orderKey === 'desc' ? valueData()?.reverse() : valueData();
    return resultData;
};
