import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn';
export const useChartingChangeSymbolIntervalFn = (charting) => {
    return useChartingFn(charting, {
        chart2: chartInstance => (options) => {
            options.symbol && chartInstance.getState().changeSymbol(options.symbol);
            options.interval &&
                chartInstance
                    .getState()
                    .widget?.activeChart()
                    .setResolution(options.interval, () => {
                    //
                });
        },
        chart4: chartInstance => (options) => {
            options.symbol && chartInstance.changeSymbol(options.symbol);
            options.interval && chartInstance.changeInterval(options.interval);
        },
    });
};
