import { useSnapshot } from 'valtio';
import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn';
export const useChartingSymbol = (charting) => {
    return useChartingFn(charting, {
        chart2: chartInstance => function useSymbol() {
            return chartInstance((s) => s.symbol);
        },
        chart4: chartInstance => function useSymbol() {
            return useSnapshot(chartInstance).symbol;
        },
    });
};
