import { useChartingFn } from '~/modules/SDK/chart4/hooks/useChartingFn';
export const useChartingChangeIndicatorsFn = (charting) => {
    return useChartingFn(charting, {
        chart2: chartInstance => (options) => {
            chartInstance.getState().changeIndicators(options);
        },
        chart4: chartInstance => (options) => {
            chartInstance.replaceIndicators(options);
        },
    });
};
